import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  DataTable,
  DataTableBody,
  DataTableCodeBlock,
  DataTableValueBlock,
  DataTableHeader,
  DataTableRow,
  Paragraph,
  PlatformTab,
  Section,
} from '../../../../components';

const WebTab = () => {
  const data = useStaticQuery(graphql`
    query spacesWebGuidelinesCode {
      allSpacesYaml {
        edges {
          node {
            spaces {
              name
              value
              css_var
            }
          }
        }
      }
    }
  `);

  return (
    <PlatformTab>
      <Paragraph>Use the values below.</Paragraph>

      <Section>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allSpacesYaml.edges[0].node.spaces.map(item => {
              return (
                <DataTableRow layout="thirds" key={item.css_var}>
                  <DataTableValueBlock>{item.name}</DataTableValueBlock>
                  <DataTableValueBlock>{item.value}</DataTableValueBlock>
                  <DataTableCodeBlock>{item.css_var}</DataTableCodeBlock>
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
